
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { MessageExample } from './components/MessageExample';
import { Brands } from './components/Brands';
import classes from './index.module.scss';
import { Section } from '@/components/common/Section';
import planetImage from '@/images/home/planet.jpg';
import ingLogo from '@/images/home/ing-logo.png';
import ikeaLogo from '@/images/home/ikea-logo.png';
import locationIcon from '@/images/home/icons/location.svg';
import shieldIcon from '@/images/home/icons/shield.svg';
import walletIcon from '@/images/home/icons/wallet.svg';
import { HeroLeftSide } from '@/components/common/HeroLeftSide';
const HomeHero: React.FunctionComponent = () => {
    const { t } = useTranslation('home');
    return (<Section className={classes.section} backgroundColor={'gray'} disableContentPaddingTop disableContentPaddingHorizontal contentPaddingBottomSettings={{
            DefaultDesktop: '0px',
        }} disableBorderBottom>
      <main className={classes.heroContent}>
        <HeroLeftSide className={classes.heroLeftSide} header={t('Powerful and easy SMS <b-fancy>communication</b-fancy>')} subheading={t('Start sending SMS using SMS API or our user-friendly online interface')} advantages={[
            {
                iconSrc: walletIcon,
                text: t('Free trials without a credit card'),
            },
            {
                iconSrc: shieldIcon,
                text: t('Data security'),
            },
            {
                iconSrc: locationIcon,
                text: t('International SMS messaging'),
            },
        ]}/>
        <div className={classes.heroRightSide}>
          <div className={classes.heroImageWrapper}>
            <Image className={classes.heroImage} src={planetImage} alt="" priority quality={70} fill sizes="60vw"/>
          </div>
          <MessageExample className={classes.bigstarMessageExample} imgSrc={ikeaLogo} title={t('Special offer')} message={t('Save with IKEA Family! Now hundreds of products at discounted prices are waiting online and in shop.')}/>
          <MessageExample className={classes.ingMessageExample} imgSrc={ingLogo} title={t('Authorisation')} message={t('You’re logging in with your trusted profile via My ING Authorisation code: 3543113')}/>
        </div>
        <Brands />
      </main>
    </Section>);
};
export default HomeHero;

    async function __Next_Translate__getStaticProps__19174cc45fe__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/Hero/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174cc45fe__ as getStaticProps }
  