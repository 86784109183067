
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { GetStaticProps, NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Page from '../components/Page';
import clientSideConfigs, { AvailableConfigs } from '../clientSideConfigs';
import { REVALIDATE_ZAUFANE_TIME_IN_SECONDS } from '../constants';
import { getZaufaneRatingSsrProp } from '../resources/zaufane';
import getI18nConfig from '../helpers/getI18nConfig';
import PageRootProps from '@/types/PageRootProps';
import HomeHero from '@/components/pages/Home/components/Hero';
const Communication = dynamic(() => import('@/components/pages/Home/components/Communication').then((mod) => mod.Communication));
const HowDoesSmsapiWork = dynamic(() => import('@/components/pages/Home/components/HowDoesSmsapiWork'));
const OurClients = dynamic(() => import('@/components/pages/Home/components/OurClients'));
const SmsapiInNumbersSection = dynamic(() => import('@/components/pages/Home/components/SmsapiInNumbers'));
const TryOutForFreeSection = dynamic(() => import('@/components/pages/Home/components/TryOutForFree'));
const GlobalAnnouncement = dynamic(() => import('@/components/GlobalAnnouncement'));
const WhatMakesUsDifferent = dynamic(() => import('@/components/pages/Home/components/WhatMakesUsDifferent'));
const HomePage: NextPage<PageRootProps> = ({ _i18nConfig, configs, rating, }) => {
    const { t } = useTranslation('home');
    return (<Page _i18nConfig={_i18nConfig} configs={configs} title={t('SMSAPI – global SMS API service provider')} description={t('SMSAPI is a worldwide bulk SMS provider offering reliable business solutions, supporting both marketing campaigns and daily notifications.')} rating={rating} withoutAggregateRatingLdJson>
      <HomeHero />
      <Communication />
      <HowDoesSmsapiWork />
      <WhatMakesUsDifferent />
      <SmsapiInNumbersSection />
      <OurClients />
      <TryOutForFreeSection />
      <GlobalAnnouncement />
    </Page>);
};
const getStaticProps: GetStaticProps<PageRootProps> = async ({ locale, }) => {
    const props = {
        ...clientSideConfigs(locale, [
            AvailableConfigs.announcements,
            AvailableConfigs.mediaAboutUs,
            AvailableConfigs.homeIntegrations,
            AvailableConfigs.libraries,
            AvailableConfigs.benefits,
            AvailableConfigs.distinctiveFeatures,
        ]),
        ...(await getI18nConfig()),
    };
    return {
        props: {
            ...props,
            rating: await getZaufaneRatingSsrProp(props.configs.services.zaufane),
        },
        revalidate: REVALIDATE_ZAUFANE_TIME_IN_SECONDS,
    };
};
export default HomePage;

    async function __Next_Translate__getStaticProps__19174cc3ffc__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174cc3ffc__ as getStaticProps }
  